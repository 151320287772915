import { FEATURE_FLAGS, useFeatureFlags } from '@feature-flags/index';
import { newExpandedStartingPriceAssignmentSelector } from '@redux/experiments/selectors/expanded-starting-price';
import { useAppSelector } from '@redux/hooks';
import { useMemo } from 'react';

export const useIsExpandedPricingEnabled = () => {
	const featureFlags = useFeatureFlags();
	const isInExpandedStartingPrice =
		useAppSelector(newExpandedStartingPriceAssignmentSelector) ===
		'starting-price';

	return useMemo(
		() =>
			featureFlags[FEATURE_FLAGS.LISTINGS_PRICING] && isInExpandedStartingPrice,
		[featureFlags[FEATURE_FLAGS.LISTINGS_PRICING]],
	);
};
