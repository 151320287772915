import { type RefObject, useEffect } from 'react';

export type CategoryRef = RefObject<HTMLDivElement>;

export const useClickOutside = (
	ref: CategoryRef,
	onClickOutside: () => void,
) => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				onClickOutside();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, onClickOutside]);
};
